import Http from "@/services/Http";
export default {
  saveCourseOrder(data) {
    return Http().post("course/order/save", data);
  },
  getBillingAddress() {
    return Http().post("checkout/billing-address/get");
  },
  saveBillingAddress(data) {
    return Http().post("checkout/billing-address/save", data);
  },
  getTaxSettings(data) {
    return Http().post("settings/tax", data);
  },
  applyPromoCode(data) {
    return Http().post("promo-code/apply", data);
  },
  checkPurchase(data) {
    return Http().post("purchase/check", data);
  },
  saveWebinarOrder(data) {
    return Http().post("webinar/order/save", data);
  },
  saveMembership(data) {
    return Http().post("membership/save", data);
  },
  initiateEventPurchase(data) {
    return Http().post("/initiate/special-event/purchase", data);
  },
  initiateProgramPurchase(data) {
    return Http().post("/initiate/course/purchase", data);
  },
  paymentFailed(data) {
    return Http().post("/payment/failed", data);
  }
};
